<template>
	<div class="cart my-import">
		
		<!--导入失败的列表-->
		<div class="my-title clearfix" v-if="errList && errList.length>0">
			<span class="">导入失败列表</span>
		</div>
		<el-table class="my-import-table" :data="errList" stripe  v-if="errList && errList.length>0" style="width: 100%; margin-bottom: 20px">
			<el-table-column prop="goods_no" label="订货备件代码">
			</el-table-column>
			<el-table-column prop="goods_num" label="数量" align="center">
			</el-table-column>
			<el-table-column prop="err_msg" label="失败原因" align="center">
			</el-table-column>
			<el-table-column fixed="right" label="操作" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="danger" plain @click="deleteItem(scope.$index)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		
		<div class="my-title clearfix">
			<span class="">导入记录详情</span>
		</div>
		<el-table :data="list" stripe style="width: 100%" class="my-import-table">
			<el-table-column label="序号" min-width="30">
				<template slot-scope="scope">
					{{scope.$index+1}}
				</template>
			</el-table-column>
			<el-table-column label="商品" min-width="300">
				<template slot-scope="scope">
					<router-link :to="{ path: '/details', query: {id:scope.row.goods_id}}">
						<div class="title">{{scope.row.goods.goods_name}}</div>
					</router-link>
				</template>
			</el-table-column>
			<el-table-column label="仓库" width="60">
				<template slot-scope="scope">
					<div class="storehouse" v-for="(props, idx) in scope.row.goods.skuInfo.goods_props" :key="idx">
						{{ props.value.name }}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="goods.skuInfo.goods_price" label="单价（元）" align="center">
			</el-table-column>
			<el-table-column label="数量" align="center" min-width="100">
				<template slot-scope="scope">
					<el-input-number size="mini" v-model="scope.row.goods_num" :min="1"
						@change="((val, oldVal) => {onUpdateCartNum(scope.row, val, oldVal)})">
					</el-input-number>
				</template>
			</el-table-column>
			<el-table-column label="小计（元）" align="center">
				<template slot-scope="scope">
					<span
						class="orange">¥{{ (scope.row.goods.skuInfo.goods_price * scope.row.goods_num).toFixed(2) }}</span>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="danger" plain @click="deleteItem(scope.$index)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

<!-- 		<div class="cart-product">
			<ul class="clearfix" v-for="(item,index) in list" :key="index">
				<li class="choose">
					<el-checkbox v-model="item.checked" @change="checkItemClick(item)"></el-checkbox>
				</li>
				<li class="commodity clearfix">
					<router-link :to="{ path: '/details', query: {id:item.goods_id}}">
						<div class="img"><img :src="item.goods.goods_image" /></div>
						<div class="text">
							<div class="title">{{item.goods.goods_name}}</div>
							<div class="storehouse" v-for="(props, idx) in item.goods.skuInfo.goods_props" :key="idx">
								{{ props.value.name }}
							</div>
						</div>
					</router-link>
				</li>
				<li class="unit-price">¥{{ item.goods.skuInfo.goods_price }}</li>
				<li class="number">
					<el-input-number size="small" v-model="item.goods_num" :min="1"
						@change="((val, oldVal) => {onUpdateCartNum(item, val, oldVal)})">
					</el-input-number>
				</li>
				<li class="subtotal">¥{{ (item.goods.skuInfo.goods_price * item.goods_num).toFixed(2) }}</li>
				<li class="operation"><i class="el-icon-delete" @click="deleteItem(index)"></i></li>
			</ul>
		</div> -->

		<div class="cart-bottom clearfix">
			<div class="left">
				<!-- <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="checkAllClick">全选
					</el-checkbox>
					<span @click="deleteCheck()">删除选中的商品</span> -->
			</div>
			<div class="right">
				<div class="price">
					总计：<span>¥{{totalPrice}}</span>
				</div>
				<router-link :to="{ path: '/settlement', query: { mode: 'import', importId: id} }"><em
						class="settlement">结算</em></router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import * as OrderImportApi from '@/api/orderImport'
	import {
		inArray,
		arrayIntersect
	} from '@/utils/util'
	export default {
		components: {},
		data() {
			return {
				id: 0,
				isIndeterminate: false,
				total: 0,
				checkAll: false,
				checkedItemNum: 0,
				list: [],
				// 选中的商品ID记录
				checkedIds: [],
				// 选中的商品总金额
				totalPrice: '0.00'
			};
		},
		watch: {
			checkedIds() {
				console.log(this.checkedIds)
				// 计算合计金额
				this.onCalcTotalPrice()
			},
		},
		created() {
			// if (!localStorage.getItem('userId')) {
			// 	this.$message.error('请先登录')
			// 	this.$router.push("/login")
			// }
			if (this.$route.query.id) {
				this.id = this.$route.query.id
			}
			this.getCartList()
		},
		methods: {
			// 计算合计金额 (根据选中的商品)
			onCalcTotalPrice() {
				const app = this
				// 计算总金额
				let tempPrice = 0;
				app.list.forEach(item => {
					// 商品单价, 为了方便计算先转换单位为分 (整数)
					const unitPrice = item.goods.skuInfo.goods_price * 100
					tempPrice += unitPrice * item.goods_num
				})
				app.totalPrice = (tempPrice / 100).toFixed(2)
			},
			getCartList() {
				const app = this
				OrderImportApi.detail(app.id)
					.then(result => {
						console.log(result)
						app.list = result.data.list
						app.errList = result.data.errList
						// 清除checkedIds中无效的ID
						app.onClearInvalidId()
						app.onCalcTotalPrice()
					})
			},
			// 清除checkedIds中无效的ID
			onClearInvalidId() {
				const app = this
				const listIds = app.list.map(item => item.id)
				app.checkedIds = arrayIntersect(listIds, app.checkedIds)
			},
			// 全选
			checkAllClick() {
				this.isIndeterminate = false
				this.list.forEach((item, index) => {
					if (this.checkAll == false) {
						this.list[index].checked = false
					} else {
						this.list[index].checked = true
					}
				})
				this.checkItemClick()
			},
			// 商品选择
			checkItemClick() {
				let app = this
				this.checkedItemNum = 0
				let length = this.list.length
				app.checkedIds = []
				this.list.forEach((item, index) => {
					let checkedIndex = this.list[index].checked
					if (checkedIndex == true) {
						app.checkedItemNum++
						app.checkedIds.push(app.list[index].id)
					}
				})
				if (this.checkedItemNum == length) {
					this.checkAll = true
					this.isIndeterminate = false
				} else if (this.checkedItemNum < length && this.checkedItemNum > 0) {
					this.isIndeterminate = true
				} else {
					this.checkAll = false
					this.isIndeterminate = false
				}
			},
			// 提交更新购物车数量
			onUpdateCartNum(item, newValue, oldValue) {
				const app = this
				OrderImportApi.update(item.goods_id, item.goods_sku_id, newValue, item.id)
					.then(result => {
						// 更新商品数量
						app.total = result.data.cartTotal
						// 重新计算合计金额
						app.onCalcTotalPrice()
					})
					.catch(err => {
						// 还原商品数量
						item.goods_num = oldValue
						this.$message.error(err.errMsg)
					})
			},
			// 删除本行
			deleteItem(index) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = []
					ids.push(app.list[index].id)
					app.onClearCart(ids)
				}).catch(() => {});
			},
			// 删除选中的商品
			deleteCheck() {
				let app = this
				if (this.checkedIds.length <= 0) {
					this.$alert('请至少选择一个', '提示');
				} else {
					this.$confirm('确定要删除选中的商品吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						app.isIndeterminate = false
						app.onClearCart(app.checkedIds)
					}).catch(() => {});
				}
			},
			// 确认删除商品
			onClearCart(ids) {
				console.log('del:', ids)
				const app = this
				OrderImportApi.clear(ids).then(result => {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					app.getCartList()
				})
			},
		}

	}
</script>

<style lang="scss">
	@import "../../assets/css/cart.scss";
	@import "../../assets/css/my-import.scss"
</style>
