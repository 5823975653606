<template>
	<div class="cart">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<!-- 导航 -->
		<navigation>
			<template slot="breadcrumb">
				<breadcrumb title="购物车"  routerPath="/cart"></breadcrumb>
			</template>
		</navigation>
		<div class="wrapper">
			<div class="cart-title clearfix">
				<ul>
					<li class="err-code">订货备件代码</li>
					<li class="err-number">数量</li>
					<li class="err-reason">待处理原因</li>
					<li class="err-operation">操作</li>
				</ul>
			</div>

			<div class="cart-product">
				<el-empty :image-size="50" description="暂无商品" v-if="errList.length==0" style="margin:5px 0 10px 0;"></el-empty>
				<ul class="clearfix" v-for="(item,index) in errList" :key="index">
					<li class="err-code clearfix">
						{{item.goods_no}}
					</li>
					<li class="err-number">
						{{item.goods_num}}
					</li>
					<li class="err-reason">{{item.err_msg}}</li>
					<li class="err-operation">
						<el-button class="button-primary-mini" size="mini" type="primary" v-if="item.options" @click="replaceItem(item)">替换</el-button>
						<el-button class="button-warning-mini" size="mini" type="warning" @click="deleteErr(item)">删除</el-button>
					</li>
				</ul>
			</div>
		</div>
		<div class="wrapper">
			<div class="cart-title clearfix">
				<ul>
					<li class="choose">
						<el-checkbox class="checkbox-orange" v-model="checkAll" :indeterminate="isIndeterminate" @change="checkAllClick">全选
						</el-checkbox>
					</li>
					<li class="commodity">商品</li>
					<li class="unit-price">单价（元）</li>
					<li class="number">数量</li>
					<li class="subtotal">小计（元）</li>
					<li class="operation">操作</li>
				</ul>
			</div>

			<div class="cart-product">
				<el-empty :image-size="50" description="暂无商品" v-if="list.length==0" style="margin:5px 0 10px 0;"></el-empty>
				<ul class="clearfix" v-for="(item,index) in list" :key="index">
					<li class="choose">
						<el-checkbox class="checkbox-orange" v-model="item.checked" @change="checkItemClick(item)"></el-checkbox>
					</li>
					<li class="commodity clearfix">
						<router-link :to="{ path: '/details', query: {id:item.goods_id}}">
							<div class="img"><img :src="item.goods.goods_image" /></div>
							<div class="text">
								<div class="title">{{item.goods.goods_name}}</div>
								<div class="storehouse">
									订货代码：{{ item.goods.buy_code }}
								</div>
								<div class="storehouse">
									供应商代码：{{ item.goods.sup_code }}
								</div>
								<div v-if="item.goods.skuInfo" class="storehouse" v-for="(props, idx) in item.goods.skuInfo.goods_props" :key="idx">
									{{ props.value.name }}
								</div>
							</div>
						</router-link>
					</li>
					<li class="unit-price">¥{{ item.goods.skuInfo.goods_price }}</li>
					<li class="number">
						<el-input-number class="main-input-number" size="mini" v-model="item.goods_num" :min="1"
							@change="((val, oldVal) => {onUpdateCartNum(item, val, oldVal)})">
						</el-input-number>
					</li>
					<li class="subtotal">¥{{ (item.goods.skuInfo.goods_price * item.goods_num).toFixed(2) }}</li>
					<li class="operation">
						<el-button class="button-warning-mini" size="mini" type="warning" @click="deleteItem(index)">删除</el-button>
					</li>
				</ul>
			</div>

			<div class="cart-bottom clearfix">
				<div class="left">
					<el-checkbox class="checkbox-orange" v-model="checkAll" :indeterminate="isIndeterminate" @change="checkAllClick">全选
					</el-checkbox>
					<span @click="deleteCheck()">删除选中的商品</span>
				</div>
				<div class="right">
					<div class="price">
						总计：<span>¥{{totalPrice}}</span>
					</div>
					<router-link v-if="checkedIds.length"
					:to="{ path: '/settlement', query: { mode: 'cart', cartIds: checkedIds.join(',')} }"><em class="settlement">结算</em></router-link>
				</div>
			</div>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as CartApi from '@/api/cart'
	import { inArray, arrayIntersect } from '@/utils/util'
	import mainHeader from '../../components/header.vue'
	import mainFooter from '../../components/footer.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	export default {
		components: {
			mainHeader,
			mainFooter,
			navigation,
			breadcrumb
		},
		data() {
			return {
				isIndeterminate: false,
				total: 0,
				checkAll: false,
				checkedItemNum: 0,
				list: [],
				errList: [],
				// 选中的商品ID记录
				checkedIds: [],
				// 选中的商品总金额
				totalPrice: '0.00'
			};
		},
		watch:{
			checkedIds() {
				// console.log(this.checkedIds)
			  // 计算合计金额
			  this.onCalcTotalPrice()
			},
		},
		created() {
			// if(!localStorage.getItem('userId')){
			// 	this.$message.error('请先登录')
			// 	this.$router.push("/login")
			// }
			this.getCartList()
		},
		methods: {
			// 计算合计金额 (根据选中的商品)
			onCalcTotalPrice() {
			  const app = this
			  // 选中的商品记录
			  const checkedList = app.list.filter(item => inArray(item.id, app.checkedIds))
			  // 计算总金额
			  let tempPrice = 0;
			  checkedList.forEach(item => {
			    // 商品单价, 为了方便计算先转换单位为分 (整数)
			    const unitPrice = item.goods.skuInfo.goods_price * 100
			    tempPrice += unitPrice * item.goods_num
			  })
			  app.totalPrice = (tempPrice / 100).toFixed(2)
			},
			getCartList() {
			  const app = this
			  CartApi.list()
			    .then(result => {
					// console.log(result)
			      app.list = result.data.list.filter(e=>e.category_id == 0)
			      app.total = app.list.reduce((total, item) => total + item.goods_num, 0)
			      // 清除checkedIds中无效的ID
			      app.onClearInvalidId()
			    })
			  CartApi.errlist()
			    .then(result => {
			      app.errList = result.data.list
			    })
			},
			// 清除checkedIds中无效的ID
			onClearInvalidId() {
			  const app = this
			  const listIds = app.list.map(item => item.id)
			  app.checkedIds = arrayIntersect(listIds, app.checkedIds)
			},
			// 全选
			checkAllClick() {
				this.isIndeterminate = false
				this.list.forEach((item, index) => {
					if (this.checkAll == false) {
						this.list[index].checked = false
					} else {
						this.list[index].checked = true
					}
				})
				this.checkItemClick()
			},
			// 商品选择
			checkItemClick() {
				let app = this
				this.checkedItemNum = 0
				let length = this.list.length
				app.checkedIds = []
				this.list.forEach((item, index) => {
					let checkedIndex = this.list[index].checked
					if (checkedIndex == true) {
						app.checkedItemNum++
						app.checkedIds.push(app.list[index].id)
					}
				})
				if (this.checkedItemNum == length) {
					this.checkAll = true
					this.isIndeterminate = false
				} else if (this.checkedItemNum < length && this.checkedItemNum > 0) {
					this.isIndeterminate = true
				} else {
					this.checkAll = false
					this.isIndeterminate = false
				}
			},
			// 提交更新购物车数量
			onUpdateCartNum(item, newValue, oldValue) {
			  const app = this
			  CartApi.update(item.goods_id, item.goods_sku_id, newValue)
			    .then(result => {
			      // 更新商品数量
			      app.total = result.data.cartTotal
			      // 重新计算合计金额
			      app.onCalcTotalPrice()
			    })
			    .catch(err => {
			      // 还原商品数量
			      item.goods_num = oldValue
				  this.$message.error(err.errMsg)
			    })
			},
			// 删除本行
			deleteItem(index) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = []
					ids.push(app.list[index].id)
					app.onClearCart(ids)
				}).catch(() => {});
			},
			// 删除选中的商品
			deleteCheck() {
				let app = this
				if (this.checkedIds.length <= 0) {
					this.$alert('请至少选择一个', '提示');
				} else {
					this.$confirm('确定要删除选中的商品吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						app.isIndeterminate = false
						app.onClearCart(app.checkedIds)
					}).catch(() => {});
				}
			},
			// 确认删除商品
			onClearCart(ids) {
				console.log('del:', ids)
			  const app = this
			  CartApi.clear(ids).then(result => {
				  this.$message({
				  	type: 'success',
				  	message: '删除成功!'
				  });
				  app.getCartList()
				})
			},

			deleteErr(item) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					CartApi.delerr(item.id).then(res => {
						app.getCartList()()
					})
				}).catch(() => {});
			},
			replaceItem(item){
				let app = this
				CartApi.add(item.options, 0, item.goods_num).then(result => {
				  this.$message({
				  	type: 'success',
				  	message: '添加成功!',
				  })
				  CartApi.delerr(item.id).then(res => {
				  	app.getCartList()()
				  })
				})
			},
		}

	}
</script>

<style lang="scss" scoped>
	@import "../../assets/css/cart.scss"
</style>
