import request from '@/utils/request'

// api地址
const api = {
  list: 'cart.category/list',
  add: 'cart.category/add',
  del: 'cart.category/del'
}

// 页面数据
export function list() {
  return request({
    url: api.list,
    method: 'get',
  })
}

// 添加分组
export const add = (name, address_id) => {
	let data = { name, address_id }
	return request({
		url: api.add,
		method: 'post',
		data
	})
}

export const del = (id) => {
	let data = { id }
	return request({
		url: api.del,
		method: 'post',
		data
	})
}
