<template>
	<div class="cart my-import">

		<!--导入失败的列表-->
		<div class="import-title clearfix">
			<h3 class="">添加商品 （余额：{{ assets.balance }}）</h3>
			<el-button :loading="loading" type="primary" @click="addClick()" icon="el-icon-top" class="btn">快速导入</el-button>
		</div>
		<el-form :inline="true" class="query-form" @submit.native.prevent>
			<el-form-item label="备件代码或名称">
				<el-input v-model="keyword" placeholder="请输入备件代码或名称" ref="keyword" @keyup.enter.native="search"></el-input>
			</el-form-item>
			<el-form-item label="自选仓库">
				<el-select v-model="warehouse" multiple multiple-limit='3' collapse-tags placeholder="请选择自选仓库">
					<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
			<!-- 设置table列 -->
			<el-popover placement="left" trigger="hover" class="screen-popover" popper-class="screen-popper"
				@show="showPopover">
				<div class="header">
					<el-checkbox v-model="checkAll" @change="checkAllChangeFn" class="checkbox-orange">列展示</el-checkbox>
					<el-button type="text" @click="reset(true)">重置</el-button>
				</div>
				<el-divider></el-divider>
				<el-checkbox-group v-model="checkColumns" @change="changeColumns" class="checkbox-orange">
					<el-checkbox v-for="(item, key) in columns" :label="item.label"></el-checkbox>
				</el-checkbox-group>
				<div class="query-icon" slot="reference">
					<i class="el-icon-s-tools"></i><span>选择显示列</span>
				</div>
			</el-popover>
		</el-form>

		<el-table class="my-import-table" height="400" :data="goodsList.data" default-expand-all row-key="goods_id"
			:tree-props="{children: 'replaceGoods'}" style="width: 100%; margin-bottom: 20px">
			<el-table-column prop="goods_name" label="备件名称" v-if="showColumn('备件名称')" class-name="goods-name">
				<template slot-scope="scope">
					<img src="@/assets/img/pei.png" class="pei-img" v-if="scope.row.suite_goods_type == 1" />
					<img src="@/assets/img/zi.png" class="pei-img" v-if="scope.row.suite_goods_type == 2" />
					<img src="@/assets/img/ti.png" class="ti-img" v-if="scope.row.is_replace" />
					<span>{{scope.row.goods_name}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="buy_code" label="订货备件代码" v-if="showColumn('订货备件代码')">
			</el-table-column>
			<el-table-column prop="sup_code" label="供应商代码" v-if="showColumn('供应商代码')">
			</el-table-column>
			<el-table-column label="自选仓库" width="250">
				<template slot-scope="scope" class="stock">
					<el-radio-group v-if="scope.row.inventoryList" size="mini" v-model="scope.row.warehouse" fill="#FF7723" @input="radioButton(scope.row)">
						<el-radio-button :label="item.warehouse_name+':'+item.goods_num"
                             v-for="item in scope.row.inventoryList.filter(e => warehouse.indexOf(e.warehouse_name) > -1)"
                             :key="item.id" @click.native="radioButton(scope.row)"
            >
            </el-radio-button>
					</el-radio-group>
				</template>
			</el-table-column>
			<el-table-column prop="goods_price_min" label="价格" v-if="showColumn('价格')" width="100">
			</el-table-column>
			<el-table-column label="库存" v-if="showColumn('库存')" width="150">
				<template slot-scope="scope" class="stock">
					<span class="num" style="display: inline-block;width: 45px;">{{ scope.row.inventory_num }}</span>
					<el-button v-if="scope.row.inventory_type == InventoryTypeEnum.ACCURATE.value" size="mini" type="primary"
						@click="stockDetails(scope.row)">查看</el-button>
				</template>
			</el-table-column>
      <el-table-column label="二网库存" v-if="showColumn('二网库存')" width="150">
        <template slot-scope="scope" class="stock">
          <span class="num" style="display: inline-block;width: 45px;">{{ getSubInventoryNum(scope.row) }}</span>
          <el-button size="mini" type="primary"
                     @click="subInventoryDetails(scope.row)">查看</el-button>
        </template>
      </el-table-column>
			<el-table-column prop="buy_flag" label="停采代码" v-if="showColumn('停采代码')" width="100">
			</el-table-column>
			<el-table-column label="操作" align="center" v-if="showColumn('操作')" width="200">
				<template slot-scope="scope">
					<el-button v-if="scope.row.suite_goods_type != 2" size="mini" type="primary"
						@click="add(scope.row)">{{scope.row.is_replace?'添加替换件':'添加'}}</el-button>
					<el-button v-if="scope.row.suite_goods_type == 2" size="mini" type="text" disabled>子件不能单独添加</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="paging">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				:current-page.sync="currentPage" :page-size="pagesize" layout="prev, pager, next, jumper"
				:total="goodsList.total">
			</el-pagination>
		</div>

		<!--导入失败的列表-->
		<div class="my-title clearfix" v-if="errList && errList.length>0">
			<span class="">待处理列表</span>
		</div>
		<el-table class="my-import-table" :data="errList" stripe v-if="errList && errList.length>0"
			style="width: 100%; margin-bottom: 20px">
			<el-table-column prop="goods_no" label="订货备件代码">
			</el-table-column>
			<el-table-column prop="goods_num" label="数量" align="center">
			</el-table-column>
			<el-table-column prop="err_msg" label="失败原因" align="center">
			</el-table-column>
			<el-table-column fixed="right" label="操作" align="center">
				<template slot-scope="scope">
					<el-button v-if="scope.row.err_type == 'replace'" size="mini" type="primary" plain
						@click="replaceItem(scope.row)">替换</el-button>
					<el-button size="mini" type="danger" class="button-danger-mini" plain
						@click="deleteErr(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 已添加商品列表 -->
		<div class="import-title clearfix">
			<h3 class="">已添加商品列表</h3>

		</div>
		<el-tabs v-model="activeName" class="main-tabs grouping-tabs">
			<el-tab-pane v-for="(item, index) in categoryList" :key="item.id" :name="item.id">
				<span slot="label" class="grouping-tabs-label">
					<!-- <span v-if="item.add_time"><i>日期:</i> {{item.add_time.split(' ')[0]}}</span>
					<span v-if="item.add_time"><i>时间: </i>{{item.add_time.split(' ')[1]}}</span> -->
					<span>{{item.name}}</span>
					<span>种类:{{item.list.length}}</span>
					<span>总计:¥<i>{{item.totalPrice}}</i> </span>
					<i v-if="item.id" class="el-icon-delete" @click="deleteCategory(item)"></i>
				</span>
				<el-table :data="item.list" stripe style="width: 100%" class="my-import-table" :key="isUpdate">
					<el-table-column label="" min-width="30">
						<template slot-scope="scope">
							<el-checkbox class="checkbox-orange" v-model="scope.row.checked"
								@change="checkItemClick(index)"></el-checkbox>
						</template>
					</el-table-column>
					<el-table-column label="订货备件代码" prop="goods.buy_code" min-width="120">
					</el-table-column>
					<el-table-column label="供应商代码" prop="goods.sup_code" min-width="80">
					</el-table-column>
					<el-table-column label="商品" min-width="280">
						<template slot-scope="scope">
							<!-- <router-link :to="{ path: '/details', query: {id:scope.row.goods_id}}"> -->
							<div class="title">{{scope.row.goods.goods_name}}</div>
							<!-- </router-link> -->
						</template>
					</el-table-column>
					<el-table-column label="自选仓库" prop="warehouse_name"></el-table-column>
					<el-table-column prop="goods.skuInfo.goods_price" label="单价" align="center" min-width="80">
					</el-table-column>
					<el-table-column label="数量" align="center" min-width="100">
						<template slot-scope="scope">
							<el-input-number size="mini" v-model="scope.row.goods_num" :min="1" class="main-input-number"
								@change="((val, oldVal) => {onUpdateCartNum(scope.row, val, oldVal)})">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="小计" align="center" min-width="80">
						<template slot-scope="scope">
							<span class="red">¥{{ (scope.row.goods.skuInfo.goods_price * scope.row.goods_num).toFixed(2) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="danger" class="button-danger-mini" plain
								@click="deleteItem(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="cart-bottom clearfix">
					<div class="left">
						<el-checkbox class="checkbox-orange" v-model="item.checkAll" :indeterminate="item.isIndeterminate"
							@change="checkAllClick(index)">全选
						</el-checkbox>
						<span @click="deleteCheck(index)">删除选中的商品</span>
					</div>
					<div class="right">
						<div class="number">总数量:{{item.list.reduce((sum, e) => sum + e.goods_num, 0)}}</div>
						<div class="price">
							总计：<span>¥{{item.totalPrice}}</span>
						</div>
						<router-link v-if="item.checkedIds&&item.checkedIds.length!==0" :to="{ path: '/settlement', query: {
                                mode: 'cart',
    							cartIds: item.checkedIds.join(','),
    							address_id:
    							item.address_id
                            }
                        }"><em class="settlement">结算</em></router-link>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane name="addCategory" disabled class="add-btn">
				<span slot="label">
					<el-button size="mini" type="primary" @click="addCategory()" class="btn"
						icon="el-icon-circle-plus-outline">添加分组</el-button>
				</span>
			</el-tab-pane>
		</el-tabs>
		<!-- 导入商品 -->
		<el-dialog title="导入商品" :visible.sync="dialogVisible" width="30%">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="下载导入模板" prop="name">
					<a
						href="https://yuweiyeke.oss-cn-zhangjiakou.aliyuncs.com/static/files/%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20v2.xlsx">下载</a>
				</el-form-item>
				<el-form-item label="分组" label-width="80px">
					<el-select v-model="importForm.category_id" placeholder="请选择分组">
						<el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择导入文件" prop="name">
					<el-upload :action="uploadUrl" :auto-upload="true" :limit="1" ref="upload" :headers="headers"
						:on-success="uploadSucc">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传Excel文件</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button :loading="loading" type="primary" @click="handleImport()">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 添加商品 -->
		<el-dialog title="添加商品" :visible.sync="dialogAddVisible" width="350px" @close="goodsClose">
			<el-form>
				<el-form-item label="分组" label-width="80px">
					<el-select v-model="addform.category_id" placeholder="请选择分组" @change="selectGroupChange">
						<el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<p class="red duplicate-tips" v-if="duplicateData">此分组该商品已添加，请修改数量，该数量为要修改成的数量，请注意！</p>
				</el-form-item>
				<el-form-item label="修改数量" label-width="80px" v-if="duplicateData">
					<el-input v-model="addform.goodsNum" autocomplete="off" ref="addnum"
						@keyup.enter.native="handleAdd(false)"></el-input>
				</el-form-item>
				<el-form-item label="添加数量" label-width="80px" v-if="!duplicateData">
					<el-input v-model="addform.goodsNum" autocomplete="off" ref="addnum"
						@keyup.enter.native="handleAdd"></el-input>
				</el-form-item>
        <el-form-item v-if="addform.warehouse_name" label="仓库" label-width="80px">
          <span>{{addform.warehouse_name}}</span>
        </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleAdd(false)" v-if="duplicateData">修改</el-button>
				<el-button type="primary" @click="handleAdd" v-if="!duplicateData">添加</el-button>
			</div>
		</el-dialog>
		<!-- 添加分组 -->
		<el-dialog title="添加分组" :visible.sync="dialogAddCategoryVisible" width="500px" :destroy-on-close="true">
			<el-form>
				<el-form-item label="名称" label-width="80px">
					<el-input v-model="categoryform.name" autocomplete="off" ref="addcategory"
						@keyup.enter.native="handleAddCategory"></el-input>
				</el-form-item>
				<el-form-item label="收货地址" label-width="80px">
					<el-select v-model="categoryform.address_id" placeholder="请选择收货地址" clearable filterable
						@change="addressSelectChange" style="width: 100%;">
						<el-option v-for="(item) in addressList" :key="item.address_id"
							:label="item.name+item.phone+item.region.province+item.region.city+item.region.region+item.detail"
							:value="item.address_id">
							<span>{{ item.name }}</span>
							<span style="margin: 0 10px;color: #8492a6;">{{ item.phone }}</span>
							<span><span v-for="(region, idx) in item.region" :key="idx">{{ region }}</span>{{ item.detail }}</span>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddCategoryVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleAddCategory">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 查看库存 -->
		<el-dialog title="库存详情" :visible.sync="dialogStockVisible" width="600px" :destroy-on-close="true">
			<el-table :data="tableStockData.slice((stockCurrentPage-1)*stockPagesize,stockCurrentPage*stockPagesize)">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				<el-table-column prop="warehouse_name" label="仓库名称"></el-table-column>
				<el-table-column prop="goods_num" label="数量"></el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" class="button-primary-mini" plain
							@click="addWarehouse(scope.row.warehouse_name+':'+scope.row.goods_num)">添加</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="paging">
				<el-pagination @size-change="stockSizeChange" @current-change="stockCurrentChange" background
					:current-page.sync="stockCurrentPage" :page-size="stockPagesize" layout="prev, pager, next, jumper"
					:total="tableStockData.length">
				</el-pagination>
			</div>
		</el-dialog>

    <el-dialog title="二网库存详情" :visible.sync="dialogSubInventoryVisible" width="600px" :destroy-on-close="true">
      <el-table :data="tableSubInventoryData.slice((stockCurrentPage-1)*stockPagesize,stockCurrentPage*stockPagesize)">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="user_name" label="仓库名称"></el-table-column>
        <el-table-column prop="goods_num" label="数量"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination @size-change="stockSizeChange" @current-change="stockCurrentChange" background
                       :current-page.sync="stockCurrentPage" :page-size="stockPagesize" layout="prev, pager, next, jumper"
                       :total="tableSubInventoryData.length">
        </el-pagination>
      </div>
    </el-dialog>

	</div>
</template>

<script>
	import * as CartApi from '@/api/cart'
	import * as CategoryApi from '@/api/cart/category'
	import * as GoodsApi from '@/api/goods'
	import * as UserApi from '@/api/user'
	import * as AddressApi from '@/api/address'
  import * as WarehouseApi from '@/api/warehouse'
	import {
		inArray,
		arrayIntersect
	} from '@/utils/util'
	import {
		InventoryTypeEnum
	} from '@/common/enum/user'
	export default {
		components: {},
		data() {
			return {
				// 自选仓库
				warehouse:'',
				warehouseOptions:[],
				isUpdate: false,
				currentPage: 1,
				pagesize: 10,
				stockCurrentPage: 1,
				stockPagesize: 10,
				InventoryTypeEnum,
				duplicateData: false,
				tableStockData: [],
        tableSubInventoryData: [],
				addressList: [],
				ruleForm: [],
				columns: [{
						label: '备件名称',
						isShow: true
					},
					{
						label: '订货备件代码',
						isShow: true
					},
					{
						label: '供应商代码',
						isShow: false
					},
					{
						label: '价格',
						isShow: true
					},
					{
						label: '库存',
						isShow: true
					},
          {
            label: '二网库存',
            isShow: true
          },
					{
						label: '停采代码',
						isShow: true
					},
					{
						label: '操作',
						isShow: true
					}
				],
				checkColumns: [],
				checkAll: false,
				uploadUrl: process.env.VUE_APP_BASE_API + 'upload/image',
				headers: {
					"Access-Token": localStorage.getItem('token')
				},
				isIndeterminate: false,
				total: 0,
				checkedItemNum: 0,
				list: [],
				errList: [],
				goodsList: [],
				// 选中的商品ID记录
				checkedIds: [],
				// 选中的商品总金额
				totalPrice: '0.00',
				dialogVisible: false,
				fileId: 0,
				keyword: '',
				dialogAddVisible: false,
				dialogStockVisible: false,
        dialogSubInventoryVisible: false,
				addform: {
					goodsId: 0,
					category_id: 0,
					goodsNum: '',
				},
				// 账户资产
				assets: {
					balance: '--',
					points: '--',
					coupon: '--'
				},
				categoryList: [],
				dialogAddCategoryVisible: false,
				categoryform: {
					name: '',
					addressSelectValue: ''
				},
				key: 0,
				importForm: {
					fileId: '',
					category_id: ''
				},
				activeName: 0,
        currentRecord: {},
        loading: false
			};
		},
		watch: {
			activeName() {
				this.addform.category_id = Number(this.activeName)
			},
			checkedItemNum() {
				// 计算合计金额
				this.onCalcTotalPrice()
			},
		},
		created() {
			// if (!localStorage.getItem('userId')) {
			// 	this.$message.error('请先登录')
			// 	this.$router.push("/login")
			// }
			this.getList()
			this.getAsset()
			this.$nextTick(() => {
				this.$refs.keyword.focus()
			})
			this.getAddressList()
      this.getWarehouseList()
		},
		methods: {
      getWarehouseList(){
        WarehouseApi.list().then(response => {
          this.warehouseOptions = response.data.list
        })
      },
			//添加自选仓库
			addWarehouse(warehouse_name){
				this.currentRecord.warehouse = warehouse_name
        this.add(this.currentRecord)
			},
			stockSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			stockCurrentChange(val) {
				this.stockCurrentPage = val
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.search()
			},
			// 获取收货地址列表
			getAddressList() {
				const app = this
				return new Promise((resolve, reject) => {
					AddressApi.list()
						.then(result => {
							app.addressList = result.data.list
							resolve(result)
						})
						.catch(err => reject(err))
				})
			},
			// 分组收货地址
			addressSelectChange(val) {

			},
			// table列显示隐藏 开始
			// 全选复选框事件监听
			checkAllChangeFn(val) {
				if (val) {
					// 全选
					this.reset(true);
				} else {
					// 反全选
					this.reset(false);
				}
			},
			// 重置，flag: Boolean，全部重置为flag
			reset(flag) {
				this.columns.forEach(item => {
					item.isShow = flag;
				})
				this.showPopover();
				this.refreshTable();
			},
			// 表格列是否显示的方法
			showColumn(currentColumn) {
				return this.columns.find(item => item.label == currentColumn).isShow;
			},
			/* 选择列 */
			changeColumns(val) {
				this.columns.forEach(item => {
					item.isShow = false;
				})
				// columns将val数组存在的值设为true，不存在的设为false
				val?.forEach(item => {
					let current = this.columns.find(i => i.label == item)
					current.isShow = true;
				})
				// 判断是否全选
				this.judgeIsCheckAll();
				this.refreshTable();
			},
			// 重新渲染表格
			refreshTable() {
				this.$nextTick(() => {
					this.$refs.table.doLayout();
				})
			},
			// 气泡框出现
			showPopover() {
				this.checkColumns = []
				this.columns.forEach(item => {
					if (item.isShow) {
						this.checkColumns.push(item.label)
					}
				})
				// 判断是否全选
				this.judgeIsCheckAll();
			},
			// 判断是否全选
			judgeIsCheckAll() {
				// 选中的长度 = 表格列的长度  全选按钮就选中
				if (this.checkColumns.length == this.columns.length)
					this.checkAll = true
				else
					this.checkAll = false
			},
			// table列显示隐藏 结束
			getList() {
				this.getCartList()
				this.getErrList()
			},
			// 计算合计金额 (根据选中的商品)
			onCalcTotalPrice() {
				const app = this
				app.categoryList.forEach(category => {
					// 选中的商品记录
					const checkedList = category.list.filter(item => inArray(item.id, category.checkedIds))

					// 计算总金额
					let tempPrice = 0;
					checkedList.forEach(item => {
						// 商品单价, 为了方便计算先转换单位为分 (整数)
						const unitPrice = item.goods.skuInfo.goods_price * 100
						tempPrice += unitPrice * item.goods_num
					})
					category.totalPrice = (tempPrice / 100).toFixed(2)
				})
				app.key++
			},
			getCartList() {
				const app = this
				CartApi.list()
					.then(result => {
						// console.log(result)
						app.list = result.data.list
						app.total = result.data.cartTotal
						// 清除checkedIds中无效的ID
						app.onClearInvalidId()
            app.getCategoryList()
						app.onCalcTotalPrice()
					})
			},
			getErrList() {
				const app = this
				CartApi.errlist()
					.then(result => {
						// console.log(result)
						app.errList = result.data.list
					})
			},
			getCategoryList() {
				const app = this
				CategoryApi.list()
					.then(result => {
						app.categoryList = [{
							id: 0,
							name: '未分组',
							add_time: ''
						}].concat(result.data.list)
						app.categoryList.forEach((item, index) => {
							item.totalPrice = '0.00'
							item.isIndeterminate = false
							item.list = []
							app.list.forEach(e => {
								let goods = e.goods
								let skuInfo = goods.skuInfo
								let skuList = goods.skuList
								if (skuList && skuList.length > 0) {
									let sku = skuList.find(sku => sku.id == skuInfo.id)
									if (sku) {
										skuInfo['goods_price'] = sku['goods_price']
									} else {
										skuInfo['goods_price'] = goods['goods_price_min']
									}
								}
								if (e.category_id == item.id) {
									e.checked = true
									item.list.push(e)
								}
							})
							this.checkAllClick(index)
						})
					})
			},
			// 清除checkedIds中无效的ID
			onClearInvalidId() {
				const app = this
				const listIds = app.list.map(item => item.id)
				// app.checkedIds = arrayIntersect(listIds, app.checkedIds)
			},
			// 全选
			checkAllClick(idx) {
				this.categoryList[idx].isIndeterminate = false
				this.categoryList[idx].list.forEach((item, index) => {
					if (this.categoryList[idx].checkAll == false) {
						this.categoryList[idx].list[index].checked = false
					} else {
						this.categoryList[idx].list[index].checked = true
					}
				})
				this.checkItemClick(idx)
			},
			// 商品选择
			checkItemClick(idx) {
				this.isUpdate = !this.isUpdate;
				console.log(idx)
				console.log(this.categoryList[idx].list)
				let app = this
				app.categoryList[idx].checkedItemNum = 0
				let length = this.categoryList[idx].list.length
				app.categoryList[idx].checkedIds = []
				this.categoryList[idx].list.forEach((item, index) => {
					let checkedIndex = this.categoryList[idx].list[index].checked
					if (checkedIndex == true) {
						app.categoryList[idx].checkedItemNum++
						app.categoryList[idx].checkedIds.push(app.categoryList[idx].list[index].id)
					}
				})
				if (app.categoryList[idx].checkedItemNum == length) {
					this.categoryList[idx].checkAll = true
					this.categoryList[idx].isIndeterminate = false
				} else if (app.categoryList[idx].checkedItemNum < length && app.categoryList[idx].checkedItemNum > 0) {
					this.categoryList[idx].isIndeterminate = true
				} else {
					this.categoryList[idx].checkAll = false
					this.categoryList[idx].isIndeterminate = false
				}
				this.checkedItemNum++
				this.key++
			},
			// 提交更新购物车数量
			onUpdateCartNum(item, newValue, oldValue) {
				const app = this
				CartApi.update(item.goods_id, item.goods_sku_id, newValue, item.category_id, item.warehouse_name)
					.then(result => {
						app.getList()
						// 更新商品数量
						app.total = result.data.cartTotal
						// 重新计算合计金额
						app.onCalcTotalPrice()
					})
					.catch(err => {
						// 还原商品数量
						item.goods_num = oldValue
						this.$message.error(err.errMsg)
					})
			},
			// 删除本行
			deleteItem(id) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let ids = []
					ids.push(id)
					app.onClearCart(ids)
				}).catch(() => {});
			},
			// 删除选中的商品
			deleteCheck(idx) {
				let category = this.categoryList[idx]
				let app = this
				if (category.checkedIds.length <= 0) {
					this.$alert('请至少选择一个', '提示');
				} else {
					this.$confirm('确定要删除选中的商品吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						category.isIndeterminate = false
						app.onClearCart(category.checkedIds)
					}).catch(() => {});
				}
			},
			// 确认删除商品
			onClearCart(ids) {
				console.log('del:', ids)
				const app = this
				CartApi.clear(ids).then(result => {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					app.getList()
				})
			},
			addClick() {
				this.dialogVisible = true
				this.$refs.upload.clearFiles()
			},
			uploadSucc(response, file, fileList) {
				let app = this
				app.importForm.fileId = fileList[0].response.data.fileInfo.file_id

			},
			handleImport() {
				let app = this
				if (app.importForm.category_id == '') {
					app.importForm.category_id = 0
				}
				if (!app.importForm.fileId) {
					app.$message.error('请选择导入文件')
					return
				}
        app.loading = true
				CartApi.goodsImport(app.importForm.fileId, app.importForm.category_id)
					.then(result => {
						app.$message.success(result.message)
						app.dialogVisible = false
						app.importForm.fileId = ''
						app.importForm.category_id = ''
						app.getList()
            app.loading = false
					})
			},
			replaceItem(item) {
				let app = this
				CartApi.add(item.options, 0, item.goods_num, item.category_id).then(result => {
					this.$message({
						type: 'success',
						message: '添加成功!',
					})
					CartApi.delerr(item.id).then(res => {
						app.getList()
					})
				})
			},
			deleteErr(item) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					CartApi.delerr(item.id).then(res => {
						app.getList()
					})
				}).catch(() => {});
			},
			search() {
				let app = this
				if (!app.keyword) {
					return
				}
				const param = {
					sortType: 'all',
          restrict_type: true,
					sortPrice: 1,
					categoryId: 0,
					goodsName: app.keyword,
					page: app.currentPage,
					pagesize: app.pagesize
				}

				GoodsApi.list(param).then(result => {
					// 合并新数据
					const newList = result.data.list
					app.goodsList = newList
					app.goodsList.data.forEach((item) => {
						if (item.replaceGoods) {
							item.replaceGoods.is_replace = true
							item.replaceGoods = [item.replaceGoods]
						}
					})
					app.$forceUpdate()
				})

			},
      stockDetails(row) {
        let app = this
        app.currentRecord = row
        let list = row.inventoryList
        list.sort((a, b) => {
          return b.goods_num - a.goods_num
        })
        app.tableStockData = list
        app.stockCurrentPage = 1
        app.dialogStockVisible = true
      },
			// 自选仓库选择
			radioButton(row){
				this.add(row)
			},
			//添加
			add(goods) {
        console.log(goods)
        let warehouse_name = ''
        if(goods.warehouse && goods.warehouse.split(':').length>1){
          warehouse_name = goods.warehouse.split(':')[0]
        }
				let app = this
				app.addform.goodsId = goods.goods_id
				app.addform.goodsNum = 1
        app.addform.warehouse_name = warehouse_name
				app.dialogAddVisible = true
				this.duplicate()
				this.$nextTick(() => {
					app.$refs.addnum.focus()
				})
			},
			// 添加商品弹窗关闭
			goodsClose() {
				this.duplicateData = false
			},
			// 添加商品选择分组
			selectGroupChange() {
				this.duplicate()
			},
			// 判断是否已有该商品
			duplicate() {
				let app = this
				app.categoryList.forEach((item, index) => {
					if (item.id == app.addform.category_id) {
						let index = item.list.findIndex((items) => {
							return items.goods_id == app.addform.goodsId
                  && items.warehouse_name == app.addform.warehouse_name
						})
						if (index == -1) {
							this.duplicateData = false
						} else {
							this.duplicateData = true
						}
					}
				})
			},
			handleAdd(isAdd = true) {
				let app = this
				// console.log(app.addform.category_id)
				if (app.addform.category_id === '') {
					app.$message.error('请选择分组')
					return
				}
				if (!app.addform.goodsNum) {
					app.$message.error('请输入添加数量')
					return
				}
				let func = CartApi.add
				if (!isAdd) {
					func = CartApi.update
				}
				func(
            app.addform.goodsId,
            0,
            app.addform.goodsNum,
            app.addform.category_id,
            app.addform.warehouse_name
        ).then(result => {
					this.$message({
						type: 'success',
						message: isAdd ? '添加成功!' : '修改成功!',
					})
				}).finally(() => {
					// app.activeName=app.addform.category_id
					app.getList()
					app.addform.goodsId = 0
					app.addform.goodsNum = ''
          app.addform.warehouse_name = ''
					// app.addform.category_id = ''
					app.dialogAddVisible = false
				})
			},
			addCategory() {
				this.dialogAddCategoryVisible = true
			},
			handleAddCategory() {
				let app = this
				if (!app.categoryform.name) {
					app.$message.error('请输入分组名称')
					return
				}
				CategoryApi.add(app.categoryform.name, app.categoryform.address_id).then(result => {
					this.$message({
						type: 'success',
						message: '添加成功!',
					})
					app.dialogAddCategoryVisible = false
				}).finally(() => {
					app.getCategoryList()
				})
			},
			getAsset() {
				let app = this
				UserApi.assets()
					.then(result => {
						app.assets = result.data.assets
					})
			},
			deleteCategory(item) {
				let app = this
				this.$confirm('确定要删除该行吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					CategoryApi.del(item.id).then(res => {
						app.getList()
					})
				}).catch(() => {});
			},
      getSubInventoryNum(item) {
        return item.subInventory.reduce((sum, e) => sum + e.goods_num, 0)
      },
      subInventoryDetails(row) {
        let app = this
        app.currentRecord = row
        let list = row.subInventory
        app.tableSubInventoryData = list
        app.stockCurrentPage = 1
        app.dialogSubInventoryVisible = true
      },
		}

	}
</script>
<style lang="scss">
	.screen-popper {
		border-radius: 8px;

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		/* 分割线 */
		.el-divider {
			margin: 10px 0;
		}

		/* 复选框 */
		.el-checkbox-group {
			display: flex;
			flex-direction: column;
		}
	}
</style>
<style lang="scss" scoped>
	@import "../../assets/css/cart.scss";
	@import "../../assets/css/my-import.scss";
	::v-deep.el-radio-group{
		.el-radio-button__inner{
			padding:4px 6px!important;
		}
	}

	::v-deep.el-pagination {
		.number {
			width: auto;
		}
	}

	.query-form {
		position: relative;

		.el-form-item {
			// margin-right: 0px;

			::v-deep.el-input {
				.el-input__inner {
					border-radius: 0;
				}
			}

			.el-button {
				border-radius: 0;
			}
		}

		.screen-popover {
			.query-icon {
				position: absolute;
				right: 0;
				top: 10px;
				display: flex;
				align-items: center;
				cursor: pointer;
				color: $main-text-color-light;

				i {
					width: 26px;
					height: 26px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 22px;
					border: 1px solid $main-text-color-light;
					border-radius: 26px;
					margin: 0 5px 0 0;
				}

				span {
					white-space: nowrap;
				}
			}

		}
	}

	::v-deep.main-input-number {

		.el-input-number__decrease,
		.el-input-number__increase {
			line-height: 26px;
		}
	}

	.stock {
		.el-button {
			margin: 0 0 0 10px;
		}
	}

	.duplicate-tips {
		line-height: 20px;
		margin: 10px 0 0 0;
	}

	::v-deep.my-import-table {
		.el-table__row--level-0 {
			background: rgba($color: $main-color, $alpha: 0.12) !important;
		}

		.el-table__row--level-1 {
			background: rgba($color: $main-color, $alpha: 0.08) !important;
		}

		.goods-name {
			display: flex;
			align-items: center;

			.cell {
				display: flex;
			}

			.pei-img,
			.ti-img {
				width: 14px;
				height: 16px;
				margin: 3px 5px 0 0;
			}
		}
	}
</style>
